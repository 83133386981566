<app-modal-header title="회사정보/약관" />
<ion-content class="ion-padding">
  <mat-tab-group color="warn" [disablePagination]="true"  mat-align-tabs="start" [(selectedIndex)]="tabIndex">
    <mat-tab label="회사정보">
      <div class="flex flex-col gap-2 py-4">
          <p><span class="font-medium text-gray-500">업체명 :</span> {{settingStore.get('footer:name')}}</p>
          <p><span class="font-medium text-gray-500">대표자명 :</span> {{settingStore.get('footer:ceo')}}</p>
        <p><span class="font-medium text-gray-500">주소 :</span> {{settingStore.get('footer:address')}}</p>
        <p><span class="font-medium text-gray-500">사업자등록번호 :</span> {{settingStore.get('footer:businessNumber')}}</p>
      </div>
    </mat-tab>
    <mat-tab label="이용약관">
      <section class="flex flex-col gap-6 pt-4">
        <app-segment [showAll]="false" [items]="segmentItems" [ngModel]="termText()" (ngModelChange)="setCategory($event)"/>
        @switch (termType()) { @case('term:eula') {
          <h1 class="text-xl font-bold text-gray-800">약관 및 유의사항</h1>
          } @case ('term:privacy') {
          <h1 class="text-xl font-bold text-gray-800">개인정보처리방침</h1>
          } }
          <article [innerHTML]="content() | safeHtml"></article>
      </section>
    </mat-tab>

</mat-tab-group>

 
</ion-content>
<!-- <section class="p-4">
  <app-button expand (click)="modal.dismiss()">확인</app-button>
</section> -->
