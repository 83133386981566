<swiper-container freemode="true" slides-per-view="auto" space-between="4" >
  @if (showAll()) {
  <swiper-slide class="max-w-min w-fit">
    <button class="text-gray-500 whitespace-nowrap" (click)="setValue(undefined)" [class.active]="value() === undefined">전체보기</button>
  </swiper-slide>
  } @for (item of items(); track item.name) {
  <swiper-slide class="max-w-min w-fit">
    <button [class.active]="value() === item.name" (click)="setValue(item.name)" class="text-gray-500 whitespace-nowrap">
      <span> {{ item.name }} </span>
      @if (item.count && showCount()) {
      <span>{{ item.count }}</span>
      }
    </button>
  </swiper-slide>
  }
</swiper-container>
