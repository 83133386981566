import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, inject, signal } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IonContent, IonModal, IonHeader } from '@ionic/angular/standalone';
import { derivedAsync } from 'ngxtension/derived-async';
import { ModalHeaderComponent } from '../../components/modal-header/modal-header.component';
import { SettingStore } from '../../stores/setting.store';
import { ButtonComponent, SafeHtmlPipe } from '@common/angular';
import { BaseModal } from '../base.modal';
import { MatTabsModule } from '@angular/material/tabs';
import { toObservable } from '@angular/core/rxjs-interop';
import { SegmentComponent } from '../../components/segment/segment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-term',
  imports: [
    IonContent,
    CommonModule,
    ModalHeaderComponent,
    SafeHtmlPipe,
    MatTabsModule,
    SegmentComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './term.modal.html',
  styleUrl: './term.modal.scss',
})
export class TermModal extends BaseModal {
  modal!: IonModal;
  term!: string;
  tabIndex = signal<number>(0);
  settingStore = inject(SettingStore);
  sanitizer = inject(DomSanitizer);

  termText = signal<string>('이용약관');
  termType = signal<string>('term:eula');

  segmentItems = [
    {
      name: '이용약관',
    },
    {
      name: '개인정보처리방침',
    },
  ];

  override ngOnInit(): void {
    if (this.term) {
      this.tabIndex.set(1);
    }
  }

  content = derivedAsync(() => {
    const rawContent = this.settingStore.get(this.termType() as any);
    if (!rawContent) return '';
    return rawContent;
  });

  setCategory(category: any) {
    if (category === '이용약관') {
      this.termType.set('term:eula');
    } else if (category === '개인정보처리방침') {
      this.termType.set('term:privacy');
    }
  }
}
